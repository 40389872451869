import FilterTypesInterface from "interfaces/FilterTypesInterface";
import { grades } from "models/GradeModel";

const FILTER_TYPE_AGE = 1;
const FILTER_TYPE_LOCATION = 2;
const FILTER_TYPE_GENDER = 3;
const FILTER_TYPE_GRADE = 4;
const FILTER_TYPE_BIRTH_MONTH = 5;

const filterOptions = {
	gender: [
		{
			id: "male",
			value: "Male"
		},
		{
			id: "female",
			value: "Female"
		}
	],
	age: [
		{
			id: 1,
			value: "Ages 0-5"
		},
		{
			id: 2,
			value: "Ages 6-10"
		},
		{
			id: 3,
			value: "Ages 11-15"
		},
		{
			id: 4,
			value: "Ages 16-21"
		},
		{
			id: 5,
			value: "Ages 21+"
		}
	],
	birthmonth: [
		{ id: 1, value: "January" },
		{ id: 2, value: "February" },
		{ id: 3, value: "March" },
		{ id: 4, value: "April" },
		{ id: 5, value: "May" },
		{ id: 6, value: "June" },
		{ id: 7, value: "July" },
		{ id: 8, value: "August" },
		{ id: 9, value: "September" },
		{ id: 10, value: "October" },
		{ id: 11, value: "November" },
		{ id: 12, value: "December" }
	]
};

export default class FilterTypeModel {
	state: FilterTypesInterface;

	constructor(state: FilterTypesInterface) {
		this.state = state;
	}

	get id() {
		return this.state.id;
	}
	get label() {
		return this.state.label;
	}
	get type() {
		return this.state.type;
	}
	get filterField() {
		return this.state.filterField;
	}

	getFilterOptions(): object {
		switch (this.id) {
			case FILTER_TYPE_AGE:
				return filterOptions.age;
			case FILTER_TYPE_GENDER:
				return filterOptions.gender;
			case FILTER_TYPE_LOCATION:
				return [];
			case FILTER_TYPE_GRADE:
				return grades;
			case FILTER_TYPE_BIRTH_MONTH:
				return filterOptions.birthmonth;
		}
		throw `Filter options do not exist for ${this.id}`;
	}

	isDynamicType(): boolean {
		//trim because I can't seem to get rid of trailing spaces from accidental nvarchar
		return this.type.trim() == "dynamic";
	}
}
