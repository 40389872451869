import PartnerSponsoredTypePlansInterface from "./PartnerSponsoredTypePlansInterface";

export const SPONSORED_TYPE_STUDENT = 1;
export const SPONSORED_TYPE_TEACHER = 2;
export const SPONSORED_TYPE_EMPLOYEE = 3;
export const SPONSORED_TYPE_PEOPLE = 4;
export const SPONSORED_TYPE_CLASSROOM = 5;

export const AllSponsoredTypes: number[] = [
	SPONSORED_TYPE_STUDENT,
	SPONSORED_TYPE_TEACHER,
	SPONSORED_TYPE_EMPLOYEE,
	SPONSORED_TYPE_PEOPLE,
	SPONSORED_TYPE_CLASSROOM
];

export enum GridDisplayTypes {
	Columns = "column",
	Deck = "deck",
	DeckCropped = "deck-cropped"
}

export interface GridDisplayInterface {
	type: GridDisplayTypes;
	description: string;
}

export const GridDisplayOptions: GridDisplayInterface[] = [
	{ type: GridDisplayTypes.Deck, description: "Deck - Traditional grid with equal height rows." },
	{ type: GridDisplayTypes.DeckCropped, description: "Deck cropped - Traditional grid with images automatically cropped to square." },
	{ type: GridDisplayTypes.Columns, description: "Column - Masonry style grid, similar to a Pinterest board layout." },
]

export default interface SponsoredTypesInterface {
	id?: number;
	partnersId: number;
	sponsoredTypesId: number;
	customLabelSingular: string;
	customLabelPlural: string;
	description: string;
	maxSlots: number;
	showSlots: boolean;
	visibleSlots: number;
	customSlotName?: string;
	currentSponsoredTypePlansId?: number;
	currentSponsoredTypePlans?: PartnerSponsoredTypePlansInterface;
	sponsoredTypes?: any;
	maskProfiles: boolean;
	showBirthday: boolean;
	showAge: boolean;
	showLastName: boolean;
	showPublicBio: boolean;
	sponsoredTypePlans?: PartnerSponsoredTypePlansInterface[];
	gridLayout: string;
	identifier: string;
	customColumns?: CustomColumnsInterface[];
	active: boolean;
	showFullySponsored: boolean;
}

export interface CustomColumnsInterface {
	id: number;
	columnName: string;
	columnDataType: string;
	columnSequence: number;
	public: boolean;
}

export interface CustomColumnValuesInterface {
	customColumnsId: number;
	columnValue: string;
	columnName?: string;
	sponsoredsId: number;
}
