import React from "react";
import style from "./style.module.css";

interface propsTemplate {
	message?: string;
	padTop?: boolean;
}

const LoadingIndicator = (props: propsTemplate) => {
	return (
		<div className="mt-2">
			<div className={style.loadingScreen}>
				{props.message && <div>{props.message}</div>}
				<div className={style.loadingDot}>.</div>
			</div>
		</div>
	);
};

export default LoadingIndicator;
